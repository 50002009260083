.bady {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
  height: 100vh;
}

.form-signin {
  max-width: 330px;
  padding: 15px;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/* sign in  */
.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
}

.bi {
  vertical-align: -.125em;
  fill: currentColor;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.vh-100{
  height: 100vh;
}

.files2 {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
  transition: outline-offset .15s ease-in-out, background-color .15s linear;
  text-align: center !important;
  margin: 0;
  width: 100% !important;
  background-color: #ffffff;
}
.files input {
    opacity: 0!important;
    outline: 2px dashed #92b0b3;
    outline-offset: -10px;
    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
    padding: 120px 0px 85px 35%;
    text-align: center !important;
    margin: 0;
    width: 100% !important;
}
.files input:focus{     
  outline: 2px dashed #92b0b3;  outline-offset: -10px;
  -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
  transition: outline-offset .15s ease-in-out, background-color .15s linear; border:1px solid #92b0b3;
}
.files{ position:relative}
.files:after {  
  pointer-events: none;
  position: absolute;
  top: 60px;
  left: 0;
  width: 90px;
  right: 0;
  height: 100px;
  content: " ";
  background-image: url("./img/upload.svg");
  display: block;
  margin: 0 auto;
  background-size: 100%;
  background-repeat: no-repeat;
  opacity: 0.6;
}
.files:before {
  position: absolute;
  bottom: 10px;
  left: 0;  pointer-events: none;
  width: 100%;
  right: 0;
  height: 57px;
  content: "Upload your file here. ";
  display: block;
  margin: 0 auto;
  color: #000000;
  font-weight: bold;
  text-transform: capitalize;
  text-align: center;
}

.pointer-f {
  cursor: pointer;
  transition: ease-in-out all .3s;
}

.pointer-f-directory:hover {

  background-color: #8db6f3;
  text-decoration:underline
}
.pointer-f-color:hover {
  background-color: rgb(246, 193, 193);
}

/* 404 Error Page page not founf notfoun*/
#oopss {
  background: linear-gradient(-45deg, #6dc0ae, #4ab8a0);
  /* background: linear-gradient(-45deg, rgba(255,243,0, 1), rgba(239,228,0, 1)); */
  position: fixed;
  left: 0px;
  top: 0;
  width: 100%;
  height: 100%;
  line-height: 1.5em;
  z-index: 10000;
}
#error-text {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Shabnam', Tahoma, sans-serif;
  color: #000;
  direction: rtl;
}
.bg-primary-300{
  background-color: #6ea8fe;
}